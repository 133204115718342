define("ember-svg-jar/inlined/analytics-messaging", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path stroke-linecap=\"round\" stroke-linejoin=\"round\" d=\"M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "class": "w-5 h-5",
      "fill": "none",
      "viewBox": "0 0 24 24",
      "stroke": "currentColor",
      "stroke-width": "2"
    }
  };
  _exports.default = _default;
});