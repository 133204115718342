define("ember-intl/macros/intl", ["exports", "@ember/object", "@ember/application"], function (_exports, _object, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.__intlInjectionName = void 0;
  _exports.default = intl;

  /**
   * @private
   * @hide
   */
  var __intlInjectionName = "intl-".concat(Date.now().toString(36));

  _exports.__intlInjectionName = __intlInjectionName;

  function intl() {
    for (var _len = arguments.length, dependentKeysAndGetterFn = new Array(_len), _key = 0; _key < _len; _key++) {
      dependentKeysAndGetterFn[_key] = arguments[_key];
    }

    var getterFn = dependentKeysAndGetterFn.pop();
    var dependentKeys = dependentKeysAndGetterFn;
    return _object.computed.apply(void 0, ["".concat(__intlInjectionName, ".locale")].concat(dependentKeys, [function (propertyKey) {
      if (!this[__intlInjectionName]) {
        (0, _object.defineProperty)(this, __intlInjectionName, {
          value: (0, _application.getOwner)(this).lookup('service:intl'),
          enumerable: false
        });
      }

      var intl = this[__intlInjectionName];
      return getterFn.call(this, intl, propertyKey, this);
    }]));
  }
});