define("ember-svg-jar/inlined/mod_Section", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<style>.st0{fill:none;stroke-linecap:round;stroke-linejoin:round}</style><g id=\"Interface-Essential__x2F__Lists__x2F__list-add\"><g id=\"Group_481\"><g id=\"list-add\"><path id=\"Shape_2345\" class=\"st0\" d=\"M4.1 1h11.3\"/><path id=\"Shape_2346\" class=\"st0\" d=\"M4.1 5.1h11.3\"/><path id=\"Shape_2347\" class=\"st0\" d=\"M4.1 9.1H9\"/><path id=\"Shape_2348\" class=\"st0\" d=\"M4.1 13.1H9\"/><path id=\"Shape_2349\" class=\"st0\" d=\"M.9 1h.8\"/><path id=\"Shape_2350\" class=\"st0\" d=\"M.9 5.1h.8\"/><path id=\"Shape_2351\" class=\"st0\" d=\"M.9 9.1h.8\"/><path id=\"Shape_2352\" class=\"st0\" d=\"M.9 13.1h.8\"/><path id=\"Oval_322\" class=\"st0\" d=\"M15.4 18c2.2 0 4-1.8 4-4s-1.8-4-4-4-4 1.8-4 4 1.8 4 4 4z\"/><path id=\"Shape_2353\" class=\"st0\" d=\"M17.4 13.9h-4\"/><path id=\"Shape_2354\" class=\"st0\" d=\"M15.4 15.9v-4\"/></g></g></g>",
    "attrs": {
      "version": "1.1",
      "id": "Layer_1",
      "stroke": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg",
      "x": "0",
      "y": "0",
      "viewBox": "0 0 20 20",
      "xml:space": "preserve"
    }
  };
  _exports.default = _default;
});