define("ember-svg-jar/inlined/chart-pie", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z\" fill=\"#4A5568\"/><path d=\"M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z\" fill=\"#4A5568\"/>",
    "attrs": {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});