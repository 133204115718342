define("ember-svg-jar/inlined/android-brands", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M802.5 613.8c0 33.3 27.1 61.2 60.3 61.2 33.7 0 60.3-27.9 60.3-61.2V377.9c0-33.8-26.6-60.7-60.3-60.7-33.2 0-60.3 26.9-60.3 60.7v235.9zM586.9 177.9c0-15.9 13.5-28.9 29.6-28.9 16.6 0 30.2 12.9 30.2 28.9 0 16.4-13.5 29.4-30.2 29.4-16-.1-29.6-13-29.6-29.4zm-229.1 0c0-15.9 13.1-28.9 29.1-28.9 16.6 0 29.7 12.9 29.7 28.9 0 16.4-13.1 29.4-29.7 29.4-16.1-.1-29.1-13-29.1-29.4zM233.6 751.1c0 25.9 20.6 46.8 47.3 46.8h53.3v131.4c0 32.8 26.1 60.7 60.3 60.7 32.6 0 60.3-27.9 60.3-60.7V797.9h93.5v131.4c0 32.8 25.6 60.7 59.8 60.7 33.7 0 60.3-27.9 60.3-60.7V797.9h53.3c26.6 0 47.2-20.9 47.2-46.8V318.7H233.6v432.4zm534.3-470.2c-8.1-81.6-62.4-149.8-140.8-185.6l49.3-71.1c3.3-4.7 2.5-10.5-1.5-12.5-4-2.5-8.5-1-12.1 3L611 88.3c-34.7-12.4-71.4-20.4-111-20.4-39.7 0-77.4 8-111.6 20.4l-50.7-73.7c-3.5-4-8.1-6.5-12.1-3-4 2.5-5.3 7.8-2 12.5l49.8 71.1C295 132.1 239.7 198.8 232.7 280.9h535.2zM76.8 613.8c0 33.3 26.1 61.2 60.3 61.2 32.6 0 60.3-27.9 60.3-61.2V377.9c0-33.8-27.7-60.7-60.3-60.7-34.2 0-60.3 26.9-60.3 60.7v235.9z\"/>",
    "attrs": {
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 1000 1000"
    }
  };
  _exports.default = _default;
});