define("ember-svg-jar/inlined/feature", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>flag-star</title><path d=\"M23.382 2.174a1.586 1.586 0 00-1.616-.1 12.136 12.136 0 01-4.126.885 18.636 18.636 0 01-4.74-.979C9.543 1 8.38.768 5.806 1.1A1.5 1.5 0 004.5 2.59v15.02a1.5 1.5 0 001.672 1.49c2.255-.259 3.305-.039 6.442.879A19.314 19.314 0 0017.64 21a14.09 14.09 0 005.08-1.152c.672-.256 1.28-.6 1.28-1.528V3.4a1.5 1.5 0 00-.618-1.226zm-12.177 11.8l1.014-2.328a.252.252 0 00-.054-.278l-1.831-1.8a.55.55 0 01.388-.962h2.091a.249.249 0 00.226-.145l1.09-2.347a.6.6 0 011.06 0l1.09 2.347a.251.251 0 00.227.145H18.6a.551.551 0 01.387.962l-1.831 1.8a.25.25 0 00-.054.278l1.014 2.328a.589.589 0 01-.839.737l-2.493-1.4a.249.249 0 00-.245 0l-2.492 1.4a.589.589 0 01-.842-.734zM1.5 0A1.5 1.5 0 000 1.5v21a1.5 1.5 0 003 0v-21A1.5 1.5 0 001.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});