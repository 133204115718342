define("@embroider/util/ember-private-api", ["exports", "@embroider/macros/runtime"], function (_exports, _runtime2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isCurriedComponentDefinition = void 0;
  _exports.lookupCurriedComponentDefinition = lookupCurriedComponentDefinition;
  var runtime;

  if ((0, _runtime2.macroCondition)(true)) {
    // new enough ember has a real module we can import
    runtime = require("@glimmer/runtime");
  } else {
    // older ember has its own internal loader
    runtime = window.Ember.__loader.require('@glimmer/runtime');
  }

  var _runtime = runtime,
      isCurriedComponentDefinition = _runtime.isCurriedComponentDefinition,
      CurriedComponentDefinition = _runtime.CurriedComponentDefinition,
      curry = _runtime.curry,
      CurriedValue = _runtime.CurriedValue; // older embers have isCurriedComponentDefinition, new ones have CurriedValue
  // and instanceof CurriedValue seems good enough.

  _exports.isCurriedComponentDefinition = isCurriedComponentDefinition;

  if (!isCurriedComponentDefinition) {
    _exports.isCurriedComponentDefinition = isCurriedComponentDefinition = function isCurriedComponentDefinition(value) {
      return value instanceof CurriedValue;
    };
  }

  function runtimeResolver(owner) {
    var resolver = owner.lookup('renderer:-dom')._runtimeResolver;

    if (resolver) {
      return resolver;
    }

    var entry = Object.entries(owner.__container__.cache).find(function (e) {
      return e[0].startsWith('template-compiler:main-');
    });

    if (entry) {
      return entry[1].resolver.resolver;
    }

    throw new Error("@embroider/util couldn't locate the runtime resolver on this ember version");
  }

  function lookupCurriedComponentDefinition(name, owner) {
    var resolver = runtimeResolver(owner);

    if (typeof resolver.lookupComponentHandle === 'function') {
      var handle = resolver.lookupComponentHandle(name, contextForLookup(owner));

      if (handle != null) {
        return new CurriedComponentDefinition(resolver.resolve(handle), null);
      }
    } // here we're doing the same thing the internal currying does, in order to
    // generate a sane error message (even though we don't actually use
    // resolvedDefinition as part of our return value).


    var resolvedDefinition = resolver.lookupComponent(name, owner);

    if (!resolvedDefinition) {
      throw new Error("Attempted to resolve `".concat(name, "` via ensureSafeComponent, but nothing was found."));
    }

    return curry(0, name, owner, {
      named: {},
      positional: []
    });
  }

  function contextForLookup(owner) {
    if ((0, _runtime2.macroCondition)(true)) {
      return owner;
    } else {
      return {
        owner: owner
      };
    }
  }
});