define("ember-svg-jar/inlined/mod_Inbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><path d=\"M47 45a2 2 0 01-2 2H3a2 2 0 01-2-2V33h14v2a4.012 4.012 0 004 4h10a4.012 4.012 0 004-4v-2h14zM1 33l6.422-11a2 2 0 011.726-1H13M35 21h3.852a2 2 0 011.726 1L47 33M24 23V1M32 15l-8 8-8-8\" stroke-width=\"2\"/></g>",
    "attrs": {
      "viewBox": "0 0 48 48",
      "stroke": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});