define("ember-svg-jar/inlined/mod_Onboarding", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<style>.st0{fill:none;stroke-width:.8;stroke-linecap:round;stroke-linejoin:round}</style><g id=\"Interface-Essential__x2F__Login_x2F_Logout__x2F__logout-2\"><g id=\"Group_42\"><g id=\"logout-2\"><path id=\"Shape_140\" class=\"st0\" d=\"M19 10H9.6\"/><path id=\"Shape_141\" class=\"st0\" d=\"M15.9 13.1L19 10l-3.1-3.1\"/><path id=\"Shape_142\" class=\"st0\" d=\"M8 2.6h5.9c.2 0 .4.2.4.4v2\"/><path id=\"Shape_143\" class=\"st0\" d=\"M14.3 15.1v2c0 .2-.2.4-.4.4H8\"/><path id=\"Shape_144\" class=\"st0\" d=\"M1 17.2c0 .2.1.3.3.4L7.6 19c.1 0 .2 0 .3-.1.1-.1.1-.2.1-.3V1.4c0-.1 0-.2-.1-.3-.1-.1-.2-.1-.3-.1L1.3 2.4c-.2 0-.3.2-.3.4v14.4z\"/><path id=\"Oval_33\" class=\"st0\" d=\"M5.3 11.2c.6 0 1.2-.5 1.2-1.2 0-.6-.5-1.2-1.2-1.2-.6 0-1.2.5-1.2 1.2 0 .6.6 1.2 1.2 1.2z\"/></g></g></g>",
    "attrs": {
      "version": "1.1",
      "id": "Layer_1",
      "stroke": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg",
      "x": "0",
      "y": "0",
      "viewBox": "0 0 20 20",
      "xml:space": "preserve"
    }
  };
  _exports.default = _default;
});