define("ember-svg-jar/inlined/mod_Card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M1.173 3.348C.16 3.91.094 4.32.073 9.908c0 5.698.086 6.194 1.187 6.69.798.368 16.682.368 17.48 0 1.1-.496 1.187-.992 1.187-6.624 0-5.59-.086-6.086-1.1-6.626-.842-.431-16.877-.431-17.654 0zm17.46.993c.237.237.43.712.43 1.079v.647H.937V5.42c0-.367.194-.842.432-1.08.41-.41.712-.43 8.632-.43s8.222.02 8.632.43zm.43 7.337c0 3.173-.042 3.496-.43 3.885-.41.41-.713.432-8.633.432-7.92 0-8.222-.022-8.632-.432-.389-.389-.432-.712-.432-3.885V8.225h18.128v3.453z\" fill-rule=\"nonzero\" stroke-width=\".02158\"/><path d=\"M3.043 10.003c0 .413.153.435 3.479.435S10 10.416 10 10.003s-.152-.435-3.478-.435-3.479.022-3.479.435z\" fill-rule=\"nonzero\" stroke-width=\".02174\"/>",
    "attrs": {
      "stroke": "currentColor",
      "viewBox": "0 0 20 20",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "stroke-linejoin": "round",
      "stroke-miterlimit": "2"
    }
  };
  _exports.default = _default;
});