define("ember-keyboard/utils/handle-key-event", ["exports", "ember-keyboard/utils/is-key"], function (_exports, _isKey) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.handleKeyEventWithPropagation = handleKeyEventWithPropagation;

  function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function handleKeyEventWithPropagation(event, _ref) {
    var firstResponders = _ref.firstResponders,
        normalResponders = _ref.normalResponders;
    var isImmediatePropagationStopped = false;
    var isPropagationStopped = false;
    var ekEvent = {
      stopImmediatePropagation: function stopImmediatePropagation() {
        isImmediatePropagationStopped = true;
      },
      stopPropagation: function stopPropagation() {
        isPropagationStopped = true;
      }
    };

    var _iterator = _createForOfIteratorHelper(firstResponders),
        _step;

    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var responder = _step.value;
        triggerResponderListener(responder, event, ekEvent);

        if (isImmediatePropagationStopped) {
          break;
        }
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }

    if (isPropagationStopped) {
      return;
    }

    isImmediatePropagationStopped = false;
    var previousPriorityLevel = Number.POSITIVE_INFINITY;

    var _iterator2 = _createForOfIteratorHelper(normalResponders),
        _step2;

    try {
      for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
        var _responder = _step2.value;
        var currentPriorityLevel = Number(_responder.keyboardPriority);

        if (isImmediatePropagationStopped && currentPriorityLevel === previousPriorityLevel) {
          continue;
        }

        if (currentPriorityLevel < previousPriorityLevel) {
          if (isPropagationStopped) {
            return;
          }

          isImmediatePropagationStopped = false;
          previousPriorityLevel = currentPriorityLevel;
        }

        triggerResponderListener(_responder, event, ekEvent);
      }
      /* eslint-enable no-unused-vars */

    } catch (err) {
      _iterator2.e(err);
    } finally {
      _iterator2.f();
    }
  }

  function triggerResponderListener(responder, event) {
    var ekEvent = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;

    if (responder.handleKeyboardEvent) {
      if (responder.canHandleKeyboardEvent && !responder.canHandleKeyboardEvent(event)) {
        return;
      }

      responder.handleKeyboardEvent(event, ekEvent);
      return;
    }

    if (responder.keyboardHandlers) {
      Object.keys(responder.keyboardHandlers).forEach(function (responderListenerName) {
        if ((0, _isKey.default)(responderListenerName, event)) {
          if (ekEvent) {
            responder.keyboardHandlers[responderListenerName](event, ekEvent);
          } else {
            responder.keyboardHandlers[responderListenerName](event);
          }
        }
      });
      return;
    }

    throw new Error('A responder registered with the ember-keyboard service must implement either `keyboardHandlers` (property returning a dictionary of listenerNames to handler functions), or `handleKeyboardEvent(event)`)');
  }
});