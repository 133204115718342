define("ember-svg-jar/inlined/analytics-interactions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path stroke-linecap=\"round\" stroke-linejoin=\"round\" d=\"M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "class": "w-5 h-5",
      "fill": "none",
      "viewBox": "0 0 24 24",
      "stroke": "currentColor",
      "stroke-width": "2"
    }
  };
  _exports.default = _default;
});