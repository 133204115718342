define("ember-svg-jar/inlined/mod_CalendarEvent", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<style>.st0{fill:none;stroke-width:.75;stroke-linecap:round;stroke-linejoin:round}</style><g id=\"Interface-Essential__x2F__Date_x2F_Calendar__x2F__calendar-favorite_1_\"><g id=\"Group_311_1_\"><g id=\"calendar-favorite_1_\"><path id=\"Rectangle-path_95_1_\" class=\"st0\" d=\"M.3 1.9c0-.5.4-.8.8-.8h17.6c.5 0 .8.4.8.8v15.9c0 .5-.4.8-.8.8H1.2c-.5 0-.8-.4-.8-.8V1.9z\"/><path id=\"Shape_1594_1_\" class=\"st0\" d=\"M.3 5.3h19.3\"/><path id=\"Shape_1595_1_\" class=\"st0\" d=\"M10.5 7.3l1.1 2.3h2.1c.2 0 .4.1.5.3.1.2 0 .4-.1.6l-1.8 1.8 1 2.3c.1.2 0 .5-.1.6-.2.2-.4.2-.6.1L10 13.9l-2.4 1.4c-.2.1-.5.1-.6-.1-.2-.2-.2-.4-.1-.6l1-2.3L6 10.5c-.2-.1-.2-.4-.1-.6.1-.2.3-.3.5-.3h2.1l1.1-2.3c0-.2.2-.3.4-.3s.4.1.5.3z\"/></g></g></g>",
    "attrs": {
      "version": "1.1",
      "id": "Layer_1",
      "stroke": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg",
      "x": "0",
      "y": "0",
      "viewBox": "0 0 20 20",
      "xml:space": "preserve"
    }
  };
  _exports.default = _default;
});