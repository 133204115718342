define("ember-svg-jar/inlined/chore", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>tools-wench</title><path d=\"M23.317 4.484a1 1 0 00-.738-.657.986.986 0 00-.937.28l-3.831 3.965a.249.249 0 01-.211.074l-1.55-.194a.249.249 0 01-.216-.208L15.586 6.2a.255.255 0 01.067-.214l3.832-3.963a.994.994 0 00.249-.946 1 1 0 00-.683-.715 6.505 6.505 0 00-7.915 8.666.24.24 0 01-.049.26l-10 10.332a2.579 2.579 0 00-.291 3.3 2.523 2.523 0 003.811.254s8.944-9.262 10.05-10.431a.235.235 0 01.258-.055 6.505 6.505 0 008.4-8.2zM3.876 21.77a1 1 0 11-.024-1.414 1 1 0 01.024 1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});