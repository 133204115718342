define("ember-svg-jar/inlined/mod_Email", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<style>.st0{fill:none;stroke-width:.8;stroke-linecap:round;stroke-linejoin:round}</style><g id=\"Emails__x2F__Email-Actions__x2F__email-action-unread\"><g id=\"Group_82\"><g id=\"email-action-unread\"><path id=\"Rectangle-path_27\" class=\"st0\" d=\"M.7 5.6c0-.9.7-1.6 1.6-1.6h15.4c.9 0 1.6.7 1.6 1.6v9.8c0 .9-.7 1.6-1.6 1.6H2.3c-.9 0-1.6-.7-1.6-1.6V5.6z\"/><path id=\"Shape_381\" class=\"st0\" d=\"M18.9 4.5L10 11.7 1.1 4.5\"/></g></g></g>",
    "attrs": {
      "version": "1.1",
      "id": "Layer_1",
      "stroke": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg",
      "x": "0",
      "y": "0",
      "viewBox": "0 0 20 20",
      "xml:space": "preserve"
    }
  };
  _exports.default = _default;
});