define("ember-svg-jar/inlined/mod_HTML", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<style>.st0{fill:none;stroke-width:.75;stroke-linecap:round;stroke-linejoin:round}</style><g id=\"Programming-Apps-Websites__x2F__Programming-Languages__x2F__programming-language-html\"><g id=\"Group_43\"><g id=\"programming-language-html\"><path id=\"Rectangle-path_14\" class=\"st0\" d=\"M.3 2.7C.3 1.8 1.1 1 2 1h16c.9 0 1.7.8 1.7 1.7v14.6c0 .9-.8 1.7-1.7 1.7H2c-.9 0-1.7-.8-1.7-1.7V2.7z\"/><path id=\"Shape_282\" class=\"st0\" d=\"M.3 6.2h19.4\"/><path id=\"Shape_286\" class=\"st0\" d=\"M7.9 13.8V9.6\"/><path id=\"Shape_287\" class=\"st0\" d=\"M7.1 9.6h1.7\"/><path id=\"Shape_288\" class=\"st0\" d=\"M3.7 13.8V9.6\"/><path id=\"Shape_289\" class=\"st0\" d=\"M5.4 13.8V9.6\"/><path id=\"Shape_290\" class=\"st0\" d=\"M3.7 12.1h1.7\"/><path id=\"Shape_291\" class=\"st0\" d=\"M14.6 9.6V13c0 .5.4.8.8.8h.8\"/><path id=\"Shape_292\" class=\"st0\" d=\"M12.9 13.8V9.6l-1.3 2.1-1.3-2.1v4.2\"/></g></g></g>",
    "attrs": {
      "version": "1.1",
      "id": "Layer_1",
      "stroke": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg",
      "x": "0",
      "y": "0",
      "viewBox": "0 0 20 20",
      "xml:space": "preserve"
    }
  };
  _exports.default = _default;
});