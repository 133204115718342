define("ember-filestack/utils/sanitize-transformations", ["exports", "@ember/utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = sanitizeTransformations;

  function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

  function sanitizeTransformations(obj) {
    Object.keys(obj).forEach(function (key) {
      var value = obj[key];

      var type = _typeof(value);

      if (type === 'object' && value !== null) {
        sanitizeTransformations(value);

        if (Object.keys(value).length === 0) {
          delete obj[key];
        }
      } else if ((0, _utils.isNone)(value)) {
        delete obj[key];
      }
    });
  }
});